import React from 'react';
import { TextField, Grid } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import PhoneInput from '../PhoneInput';
import { useTranslation } from 'react-i18next';
import { Field } from '../../styled/Field';
import { BookingVM } from '../../viewModels';

export type BookingClientFormInputs = Pick<BookingVM, 'name' | 'phone' | 'email'>;

export interface BookingClientEditorProps {
  form: UseFormReturn<BookingClientFormInputs>;
  showEmail?: boolean;
  showPhone?: boolean;
}

const BookingClientEditor: React.FC<BookingClientEditorProps> = ({
  form,
  showEmail = true,
  showPhone = true,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          label={t('Name')}
          errorMessage={form.formState.errors.name?.message}
        >
          <TextField
            size="small"
            error={!!form.formState.errors.name}
            {...form.register('name')}
            fullWidth
          />
        </Field>
      </Grid>
      {showEmail && (
        <Grid item xs={12}>
          <Field
            label={t('Email')}
            errorMessage={form.formState.errors.email?.message}
          >
            <Controller
              name="email"
              control={form.control}
              render={() => (
                <TextField
                  size="small"
                  error={!!form.formState.errors.email}
                  {...form.register('email')}
                  fullWidth
                />
              )}
            />
          </Field>
        </Grid>
      )}
      {showPhone && (
        <Grid item xs={12}>
          <Field
            label={t('Phone')}
            errorMessage={form.formState.errors.phone?.message}
          >
            <Controller
              name="phone"
              control={form.control}
              render={({ field }) => (
                <PhoneInput
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!form.formState.errors.phone}
                />
              )}
            />
          </Field>
        </Grid>
      )}
    </Grid>
  );
};

export default BookingClientEditor;
