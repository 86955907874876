import { DbCollections } from '../shared/common';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getDbName, mapFromDbFields } from '../shared/mapping';
import { Account } from 'shared-ts/entities';
import { Booking } from 'shared-ts/entities';
import { Experience } from 'shared-ts/entities';
import { User } from 'shared-ts/entities';
import { CreateAccount } from '../firebaseFunctions';

export default class AppService {
  async createAccount(userId: string, name: string) {
    await CreateAccount({
      userId: userId,
      name: name,
    });
  }

  async getAccountById(id: string): Promise<Account> {
    const account = await getDoc(doc(db, DbCollections.accounts, id));
    return mapFromDbFields({ ...account.data(), id: account.id });
  }

  async getBookingById(id: string): Promise<Booking> {
    const booking = await getDoc(doc(db, DbCollections.bookings, id));
    return mapFromDbFields({ ...booking.data(), id: booking.id });
  }

  async getBookingsByAccountId(accountId: string): Promise<Booking[]> {
    // Step 1: Get all experiences by account ID
    const experiences = await this.getExperiencesByAccountId(accountId);
    const experienceIds = experiences.map((exp) => exp.id);

    if (experienceIds.length === 0) {
      return []; // No experiences found for the given account ID
    }

    // Step 2: Get all bookings where experience ID matches any of the experience IDs
    const bookingsRef = collection(db, DbCollections.bookings);
    const q = query(bookingsRef, where(getDbName<Booking>('experienceId'), 'in', experienceIds));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return []; // No bookings found with the given experience IDs
    }

    return querySnapshot.docs.map((docSnapshot) =>
      mapFromDbFields({ ...docSnapshot.data(), id: docSnapshot.id }),
    );
  }

  async getExperienceById(id: string): Promise<Experience | null> {
    const experienceDoc = await getDoc(doc(db, DbCollections.experiences, id));
    if (!experienceDoc.exists()) {
      return null;
    }
    const experience = mapFromDbFields<Experience>({ ...experienceDoc.data(), id: experienceDoc.id });
    return experience.deletedAt ? null : experience;
  }

  async getExperienceByLink(link: string): Promise<Experience | null> {
    const experiencesRef = collection(db, DbCollections.experiences);
    const q = query(
      experiencesRef,
      where(getDbName<Experience>('link'), '>=', link.toLowerCase()),
      where(getDbName<Experience>('link'), '<=', link.toLowerCase() + '\uf8ff')
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    // Find exact case-insensitive match
    const matchingDoc = querySnapshot.docs.find(
      doc => doc.data()[getDbName<Experience>('link')].toLowerCase() === link.toLowerCase()
    );

    if (!matchingDoc) {
      return null;
    }

    const experience = mapFromDbFields<Experience>({ ...matchingDoc.data(), id: matchingDoc.id });
    return experience.deletedAt ? null : experience;
  }

  async getExperiencesByAccountId(accountId: string): Promise<Experience[]> {
    const experiencesRef = collection(db, DbCollections.experiences);
    const q = query(
      experiencesRef,
      where(getDbName<Experience>('accountId'), '==', accountId)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return []; // No documents found with the given account ID
    }

    console.log('querySnapshot', querySnapshot.docs.map((docSnapshot) => docSnapshot.data()));

    return querySnapshot.docs
      .map((docSnapshot) => mapFromDbFields<Experience>({ ...docSnapshot.data(), id: docSnapshot.id }))
      .filter(experience => !experience.deletedAt);
  }

  async getUserById(id: string): Promise<User> {
    const user = await getDoc(doc(db, DbCollections.users, id));
    return mapFromDbFields({ ...user.data(), id: user.id });
  }
}

export const appService = new AppService();
