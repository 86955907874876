import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import PickerDrawer, { PickerOption } from '../PickerDrawer';
import useGoogleCalendars from '../../queryHelpers/useGoogleCalendars';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';
import useExperience from '../../queryHelpers/useExperience';

interface Props {
  formParams: FormParams;
  experienceId: string;
  onSelect: () => void;
}

const GoogleCalendarPickerDrawer: React.FC<Props> = ({
  formParams,
  onSelect,
  experienceId,
}) => {
  const { t } = useTranslation();
  const googleCalendarsQuery = useGoogleCalendars();
  const experienceQuery = useExperience({ id: experienceId });
  const updateExperienceMutation = useUpdateExperience(experienceId);

  const options = useMemo(() => {
    return (
      googleCalendarsQuery.data?.map(
        (x) => ({ text: x.summary!, value: x.id! }) as PickerOption<string>,
      ) || []
    );
  }, [googleCalendarsQuery.data]);

  const handleSelect = async (id: string) => {
    try {
      await updateExperienceMutation.mutateAsync({
        id: experienceId!,
        googleCalendarSettings: {
          ...{
            invitees: [],
            createGoogleMeetLink: false,
            calendarIdsForAvailability: [],
          },
          ...experienceQuery.data!.googleCalendarSettings,
          calendarIdForNewEvents: id,
        },
      });
      onSelect();
      return {};
    } catch (error) {
      return {
        error: t('Failed to update Google calendar for creating appointments'),
      };
    }
  };

  return (
    <PickerDrawer
      formParams={formParams}
      title={t('Create appointments in')}
      options={options}
      value={experienceQuery.data?.googleCalendarSettings?.calendarIdForNewEvents ?? undefined}
      onSelect={handleSelect}
      isLoading={googleCalendarsQuery.isLoading}
      confirmText={t('Select')}
    />
  );
};

export default GoogleCalendarPickerDrawer;
