import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../routes';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        p: 3,
      }}
    >
      <Typography variant="h4" component="h1">
        {t('404 - Page Not Found')}
      </Typography>
      <Typography variant="body1" align="center">
        {t('The page you are looking for does not exist or has been removed.')}
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate(routes.landing)}
        sx={{ mt: 2 }}
      >
        {t('Go to Homepage')}
      </Button>
    </Box>
  );
};

export default NotFoundPage;
