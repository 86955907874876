import React from 'react';
import { FormParams } from '../../types';
import YesNoPickerDrawer from '../YesNoPickerDrawer';
import { useTranslation } from 'react-i18next';
import useExperience from '../../queryHelpers/useExperience';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';

type Props = {
  formParams: FormParams;
  experienceId: string;
  field: 'email' | 'phone';
};

const FormFieldSettingsDrawer: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const experienceQuery = useExperience({
    id: props.experienceId,
    enabled: !!props.experienceId,
  });
  const updateExperience = useUpdateExperience(props.experienceId);

  const handleSelect = async (value: boolean) => {
    try {
      await updateExperience.mutateAsync({
        formSettings: {
          email: props.field === 'email' ? value : experienceQuery.data?.formSettings?.email ?? true,
          phone: props.field === 'phone' ? value : experienceQuery.data?.formSettings?.phone ?? true,
        },
      });
      return { error: undefined };
    } catch (error) {
      return { error: t('Unable to update form settings') };
    }
  };

  const getTitle = () => {
    switch (props.field) {
      case 'email':
        return t('Require email in booking form');
      case 'phone':
        return t('Require phone in booking form');
      default:
        return '';
    }
  };

  return (
    <YesNoPickerDrawer
      formParams={props.formParams}
      onSelect={handleSelect}
      text={getTitle()}
    />
  );
};

export default FormFieldSettingsDrawer;
