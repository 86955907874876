import React, { useState } from 'react';

import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useUpsertBooking from '../../queryHelpers/useUpsertBooking';
import useBooking from '../../queryHelpers/useBooking';
import useExperience from '../../queryHelpers/useExperience';
import { BookingVM, DateTimeSlotVM } from '../../viewModels';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import BookingClientEditor, {
  BookingClientFormInputs,
} from '../bookings/BookingClientEditor';
import BookingScheduleEditor, {
  BookingScheduleFormInputs,
} from '../bookings/BookingScheduleEditor';
import { Field } from '../../styled/Field';
import AppTransparentCard from '../AppTransparentCard';
import ConfirmationDrawer from '../ConfirmationDrawer';

interface BookingFormProps {
  experienceId: string;
  bookingId?: string;
  onSave: (bookingId: string) => void;
}

const BookingFormFormSchema = yup
  .object({
    timeSlot: yup
      .object<DateTimeSlotVM | null>({
        start: yup.number().required(i18n.t('Select time')),
        end: yup.number().required(i18n.t('Select time')),
      })
      .required(i18n.t('Select time')),
    name: yup.string().required(i18n.t('Enter name')),
    email: yup
      .string()
      .when('$requireEmail', {
        is: true,
        then: (schema) => schema.email(i18n.t('Invalid email')).required(i18n.t('Enter email')),
      }),
    phone: yup
      .string()
      .when('$requirePhone', {
        is: true,
        then: (schema) => schema.min(11, i18n.t('Enter full phone number')).required(i18n.t('Enter phone number')),
      }),
    wishes: yup.string(),
  })
  .required();

export type BookingFormFormInput = Pick<
  BookingVM,
  'name' | 'phone' | 'wishes' | 'email'
> &
  Partial<Pick<BookingVM, 'timeSlot'>>;

const BookingForm: React.FC<BookingFormProps> = (props) => {
  const { t } = useTranslation();
  const [openConfirmationDrawer, setOpenConfirmationDrawer] = useState(false);
  const experienceQuery = useExperience({
    id: props.experienceId,
    link: props.experienceId,
  });
  const bookingQuery = useBooking(props.bookingId);
  const upsertBookingMutation = useUpsertBooking();

  const form = useForm<BookingFormFormInput>({
    defaultValues: {
      email: '',
      name: '',
      phone: '',
      wishes: '',
    },
    resolver: yupResolver(BookingFormFormSchema) as any,
    context: {
      requireEmail: experienceQuery.data?.formSettings?.email ?? true,
      requirePhone: experienceQuery.data?.formSettings?.phone ?? true,
    },
  });

  const handleBook = async () => {
    form.trigger();
    if (!form.formState.isValid) {
      return;
    }

    setOpenConfirmationDrawer(true);
  };

  const handleConfirm = async () => {
    const bookingId = await upsertBookingMutation.mutateAsync({
      ...bookingQuery.data,
      ...form.getValues(),
      experienceId: experienceQuery.data?.id!,
      id: props.bookingId,
    });
    props.onSave(bookingId);
    return { error: null };
  };

  if (!experienceQuery.data) {
    return <CircularProgress />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <AppTransparentCard title={experienceQuery.data?.summary.title}>
          <Typography variant="body1">
            {experienceQuery.data?.summary.description}
          </Typography>
        </AppTransparentCard>

        <BookingScheduleEditor
          form={form as unknown as UseFormReturn<BookingScheduleFormInputs>}
          experienceId={experienceQuery.data?.id!}
        />

        <BookingClientEditor
          form={form as unknown as UseFormReturn<BookingClientFormInputs>}
          showEmail={experienceQuery.data?.formSettings?.email ?? true}
          showPhone={experienceQuery.data?.formSettings?.phone ?? true}
        />
        <Field
          label={t('Comments')}
          errorMessage={form.formState.errors.wishes?.message}
        >
          <TextField
            multiline
            size="small"
            {...form.register('wishes')}
            fullWidth
          />
        </Field>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleBook}
        >
          {i18n.t('Book Appointment')}
        </Button>
      </Box>

      <ConfirmationDrawer
        formParams={{
          open: openConfirmationDrawer,
          onClose: () => setOpenConfirmationDrawer(false),
        }}
        onConfirm={handleConfirm}
      />
    </Box>
  );
};

export default BookingForm;
