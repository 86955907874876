import { Box, IconButton, Typography } from '@mui/material';

import { SwipeableDrawer } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { FormParams } from '../types';
import { AppIcon } from './AppIcon';
import { useAppDrawerContext } from './AppDrawerContext';

type AppDrawerProps = PropsWithChildren & {
  title?: string | ReactNode;
  titlePosition?: 'left' | 'center';
  description?: string;
  formParams: FormParams;
  closeVariant?: 'icon' | 'text';
  height?: 'full';
  notScrollable?: boolean;
};

const AppDrawer: React.FC<AppDrawerProps> = ({
  title,
  description,
  formParams,
  closeVariant,
  height,
  children,
  notScrollable,
  titlePosition = 'left',
}) => {
  const { paperProps } = useAppDrawerContext();

  return (
    <SwipeableDrawer
      anchor="bottom"
      disableBackdropTransition={true}
      disableSwipeToOpen={true}
      disableDiscovery={true}
      open={formParams.open}
      onClose={formParams.onClose}
      onOpen={() => {}}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          ...paperProps?.sx
        },
      }}
      sx={{
        width: '100%',
        position: 'relative',
        '& .MuiPaper-root': {
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
          pt: 0,
          pb: 0
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            alignItems: 'start',
            width: 1,
            display: 'flex',
            pt: '16px',
            pb: '6px',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              textAlign: titlePosition,
              ml: titlePosition === 'left' ? '0' : '15px',
            }}
          >
            {title && <Typography variant="cardHeader">{title}</Typography>}
            {description && (
              <Typography variant="body1" sx={{ mt: '4px' }}>
                {description}
              </Typography>
            )}
          </Box>
          {(!closeVariant || closeVariant === 'icon') && (
            <IconButton
              sx={{ mr: '-8px', mt: '-6px' }}
              onClick={() => formParams.onClose()}
            >
              <AppIcon src="/images/CloseDrawerIcon.svg" />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            overflowY: notScrollable ? 'hidden' : 'auto',
            flexGrow: 1,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            pb: 'env(safe-area-inset-bottom)',
            height: '100%',
            minHeight: 0,
          }}
        >
          {children}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default AppDrawer;
