import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AppDrawer from '../../../../components/AppDrawer';
import { FormParams } from '../../../../types';
import useExperience from '../../../../queryHelpers/useExperience';
import { Box, Divider, Grid, Typography, useTheme, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { minutesToTime } from '../../../../helpers/pluralHelpers';
import { getBookLink, shareBookLink } from '../../../../helpers/linkHelpers';
import AppCard from '../../../../components/AppCard';
import AppCardButton from '../../../../components/AppCardButton';
import AppCardList from '../../../../components/AppCardList';
import AppErrorButton from '../../../../components/AppErrorButton';
import AppTransparentCard from '../../../../components/AppTransparentCard';
import DurationPickerDrawer from '../../../../components/experiences/DurationPickerDrawer';
import useUpdateExperience from '../../../../queryHelpers/useUpdateExperience';
import FutureBookingDaysLimitPickerDrawer from '../../../../components/experiences/FutureBookingDaysLimitPickerDrawer';
import MinimumNoticePeriodPickerDrawer from '../../../../components/experiences/MinimumNoticePeriodPickerDrawer';
import DailyBookingLimitPickerDrawer from '../../../../components/experiences/DailyBookingLimitPickerDrawer';
import GapBetweenAppointmentsPickerDrawer from '../../../../components/experiences/GapBetweenAppointmentsPickerDrawer';
import OnlyConsecutiveReservationsPickerDrawer from '../../../../components/experiences/OnlyConsecutiveReservationsPickerDrawer';
import LinkInputDrawer from '../../../../components/LinkInputDrawer';
import { SummaryEditDrawer } from '../../../../components/experiences/SummaryEditDrawer';
import { toast } from 'react-hot-toast';
import { createToastStyle } from '../../../../shared/styles';
import QRCodeDisplayDrawer from '../../../../components/experiences/QRCodeDisplayDrawer';
import DeleteConfirmationDrawer from '../../../../components/experiences/DeleteConfirmationDrawer';
import useDeleteExperience from '../../../../queryHelpers/useDeleteExperience';
import ConnectGoogleCalendarDrawer from '../../../../components/experiences/ConnectGoogleCalendarDrawer';
import { useGoogleUserInfo } from '../../../../components/googleClientProvider/useGoogleUserInfo';
import GoogleCalendarPickerDrawer from '../../../../components/experiences/GoogleCalendarPickerDrawer';
import useGoogleCalendars from '../../../../queryHelpers/useGoogleCalendars';
import EnableGoogleMeetYesNoDrawer from '../../../../components/experiences/EnableGoogleMeetYesNoDrawer';
import GoogleCalendarAvailabilitySelectDrawer from '../../../../components/experiences/GoogleCalendarAvailabilitySelectDrawer';
import DisableGoogleCalendarConfirmationDrawer from '../../../../components/experiences/DisableGoogleCalendarConfirmationDrawer';
import ScheduleEditDrawer from '../../../../components/experiences/ScheduleEditDrawer';
import ScheduleList from '../../../../components/experiences/ScheduleList';
import ParticipantsPickerDrawer from '../../../../components/experiences/ParticipantsPickerDrawer';
import FormFieldSettingsDrawer from '../../../../components/experiences/FormFieldSettingsDrawer';

interface CreateExperienceDialogProps {
  formParams: FormParams;
  experienceId: string | null;
}

export const ExperienceDetailsDrawer: React.FC<CreateExperienceDialogProps> = ({
  formParams,
  experienceId,
}) => {
  const { data, isFetched } = useExperience({
    id: experienceId!,
    enabled: !!experienceId,
  });
  const updateExperience = useUpdateExperience(experienceId!);
  const deleteExperience = useDeleteExperience({ id: experienceId! });

  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const [
    isFutureBookingDaysLimitPickerOpen,
    setIsFutureBookingDaysLimitPickerOpen,
  ] = useState(false);
  const [isMinimumNoticePeriodPickerOpen, setIsMinimumNoticePeriodPickerOpen] =
    useState(false);
  const [isDailyBookingLimitPickerOpen, setIsDailyBookingLimitPickerOpen] =
    useState(false);
  const [
    isGapBetweenAppointmentsPickerOpen,
    setIsGapBetweenAppointmentsPickerOpen,
  ] = useState(false);
  const [
    isOnlyConsecutiveReservationsPickerOpen,
    setIsOnlyConsecutiveReservationsPickerOpen,
  ] = useState(false);
  const [isLinkInputOpen, setIsLinkInputOpen] = useState(false);
  const [isSummaryEditOpen, setIsSummaryEditOpen] = useState(false);
  const [isQRCodeDisplayOpen, setIsQRCodeDisplayOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isConnectGoogleCalendarOpen, setIsConnectGoogleCalendarOpen] =
    useState(false);
  const [isGoogleCalendarPickerOpen, setIsGoogleCalendarPickerOpen] =
    useState(false);
  const [isEnableGoogleMeetOpen, setIsEnableGoogleMeetOpen] = useState(false);
  const [isGoogleCalendarAvailabilitySelectOpen, setIsGoogleCalendarAvailabilitySelectOpen] =
    useState(false);
  const [isDisableGoogleCalendarConfirmationOpen, setIsDisableGoogleCalendarConfirmationOpen] =
    useState(false);
  const [isScheduleEditOpen, setIsScheduleEditOpen] = useState(false);
  const [isParticipantsPickerOpen, setIsParticipantsPickerOpen] = useState(false);
  const [isEmailSettingsOpen, setIsEmailSettingsOpen] = useState(false);
  const [isPhoneSettingsOpen, setIsPhoneSettingsOpen] = useState(false);
  const theme = useTheme();
  const googleUserInfo = useGoogleUserInfo();
  const googleCalendars = useGoogleCalendars();

  const { t } = useTranslation();

  if (!isFetched) {
    return <></>;
  }

  const handleDurationSelect = async (minutes: number) => {
    try {
      await updateExperience.mutateAsync({
        id: experienceId!,
        summary: {
          ...data!.summary,
          duration: minutes,
        },
      });
      return {}; // Success case - no error
    } catch (error) {
      return {
        error: t('Failed to update duration'), // Or get error message from API response
      };
    }
  };

  const handleFutureBookingDaysLimitSelect = async (days: number) => {
    try {
      await updateExperience.mutateAsync({
        id: experienceId!,
        limitSettings: {
          ...data!.limitSettings,
          futureBookingDaysLimit: days,
        },
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update future booking days limit'), // Or get error message from API response
      };
    }
  };

  const handleMinimumNoticePeriodSelect = async (minutes: number) => {
    try {
      await updateExperience.mutateAsync({
        id: experienceId!,
        limitSettings: {
          ...data!.limitSettings,
          minimumNoticePeriod: minutes,
        },
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update minimum notice period'),
      };
    }
  };

  const handleDailyBookingLimitSelect = async (days: number) => {
    try {
      await updateExperience.mutateAsync({
        id: experienceId!,
        limitSettings: {
          ...data!.limitSettings,
          dailyBookingLimit: days,
        },
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update daily booking limit'),
      };
    }
  };

  const handleGapBetweenAppointmentsSelect = async (minutes: number) => {
    try {
      await updateExperience.mutateAsync({
        id: experienceId!,
        limitSettings: {
          ...data!.limitSettings,
          gapBetweenEvents: minutes,
        },
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update gap between appointments'),
      };
    }
  };

  const handleOnlyConsecutiveReservationsSelect = async (
    onlyConsecutiveReservations: boolean,
  ) => {
    try {
      await updateExperience.mutateAsync({
        id: experienceId!,
        limitSettings: {
          ...data!.limitSettings,
          onlyConsecutiveReservations: onlyConsecutiveReservations,
        },
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update only consecutive reservations'),
      };
    }
  };

  const handleLinkSave = async (link: string) => {
    try {
      await updateExperience.mutateAsync({
        id: experienceId!,
        summary: {
          ...data!.summary,
          link: link,
        },
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update link'),
      };
    }
  };

  const handleLinkCopy = () => {
    navigator.clipboard.writeText(getBookLink(data!));
    toast.success(t('Link copied'), createToastStyle(theme));
  };

  const handleDelete = async () => {
    await deleteExperience.mutateAsync(undefined, {
      onSuccess: () => {
        formParams.onClose();
        setIsDeleteConfirmationOpen(false);
      },
    });
  };

  const handleConnectedGoogleCalendar = () => {
    setIsGoogleCalendarPickerOpen(true);
  };

  const handleClickConnectGoogleCalendar = () => {
    if (!googleUserInfo.data) {
      setIsConnectGoogleCalendarOpen(true);
    } else {
      setIsGoogleCalendarPickerOpen(true);
    }
  };

  if (!data) {
    return (
      <AppDrawer formParams={formParams} title={t('Service')} titlePosition='center' height="full">
        <></>
      </AppDrawer>
    );
  }

  return (
    <AppDrawer formParams={formParams} title={t('Service')} titlePosition='center' height="full">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <AppTransparentCard
          title={
            <>
              <>{data?.summary.title}</>
              <Edit color="action" sx={{ ml: '8px', fontSize: '16px' }} />
            </>
          }
          onClick={() => setIsSummaryEditOpen(true)}
        >
          <Typography variant="body1">{data?.summary.description}</Typography>
        </AppTransparentCard>
        <AppCardList
          items={[
            {
              title: t('Duration'),
              value: minutesToTime(data?.summary.duration!),
              onClick: () => setIsTimePickerOpen(true),
            },
          ]}
        />
        <AppCard
          title={t('Link for booking')}
          onClick={() => setIsLinkInputOpen(true)}
        >
          <Typography
            variant="body1"
            sx={{ wordBreak: 'break-word', color: '#51B9FF' }}
          >
            {getBookLink(data!)}
          </Typography>
        </AppCard>
        <Grid
          container
          spacing="16px"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {[
            {
              icon: '/images/share.svg',
              title: t('Share link'),
              onClick: () => shareBookLink(data!),
            },
            {
              icon: '/images/copy.svg',
              title: t('Copy link'),
              onClick: () => handleLinkCopy(),
            },
            {
              icon: '/images/qr_code.svg',
              title: t('QR code link'),
              onClick: () => setIsQRCodeDisplayOpen(true),
            },
          ].map((x) => (
            <Grid item xs={4} key={x.title}>
              <AppCardButton
                iconPath={x.icon}
                text={x.title}
                onClick={x.onClick}
              />
            </Grid>
          ))}
        </Grid>

        <AppCard title={t('Schedule')} onClick={() => setIsScheduleEditOpen(true)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Divider />
            <ScheduleList slots={data?.availabilities ?? []} />
          </Box>
        </AppCard>

        {!data?.googleCalendarSettings?.calendarIdForNewEvents ? (
          <AppCard
            title={t('Connect Google calendar')}
            onClick={() => handleClickConnectGoogleCalendar()}
          >
            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
              {t('Connect your Google calendar for checking your availability and creating appointments there')}
            </Typography>
          </AppCard>
        ) : (
          <AppCardList
            title={t('Google calendar')}
            secondaryAction={{
              title: t('Disable'),
              onClick: () => setIsDisableGoogleCalendarConfirmationOpen(true),
            }}
            items={[
              {
                title: t('Create appointments in', {
                  count: data?.limitSettings?.futureBookingDaysLimit ?? 0,
                }),
                value:
                  googleCalendars.data?.find(
                    (x) =>
                      x.id ===
                      data?.googleCalendarSettings?.calendarIdForNewEvents,
                  )?.summary ??
                  data?.googleCalendarSettings?.calendarIdForNewEvents ??
                  undefined,
                onClick: () => setIsGoogleCalendarPickerOpen(true),
              },
              {
                title: t('Check availability in'),
                value:
                  data?.googleCalendarSettings?.calendarIdsForAvailability?.length ?? 0,
                onClick: () => setIsGoogleCalendarAvailabilitySelectOpen(true),
              },
              {
                title: t('Meeting participants'),
                value: data?.googleCalendarSettings?.invitees?.length ?? 0,
                onClick: () => setIsParticipantsPickerOpen(true),
              },
              {
                title: t('Create Google Meet'),
                value: data?.googleCalendarSettings?.createGoogleMeetLink
                  ? t('Yes')
                  : t('No'),
                onClick: () => setIsEnableGoogleMeetOpen(true),
              }
            ]}
          />
        )}
        <AppCardList
          title={t('Special conditions')}
          items={[
            {
              title: t('Booking window'),
              value: t('day_count', {
                count: data?.limitSettings?.futureBookingDaysLimit ?? 0,
              }),
              onClick: () => setIsFutureBookingDaysLimitPickerOpen(true),
            },
            {
              title: t('Minimum notice'),
              value: minutesToTime(
                data?.limitSettings?.minimumNoticePeriod ?? 0,
              ),
              onClick: () => setIsMinimumNoticePeriodPickerOpen(true),
            },
            {
              title: t('Daily limit'),
              value: t('appointment_count', {
                count: data?.limitSettings?.dailyBookingLimit ?? 0,
              }),
              onClick: () => setIsDailyBookingLimitPickerOpen(true),
            },
            {
              title: (
                <Tooltip title={t('Required break time between appointments')}>
                  <span>{t('Break time')}</span>
                </Tooltip>
              ),
              value: minutesToTime(data?.limitSettings?.gapBetweenEvents ?? 0),
              onClick: () => setIsGapBetweenAppointmentsPickerOpen(true),
            },
            {
              title: t('Consecutive only'),
              value: data?.limitSettings?.onlyConsecutiveReservations
                ? t('Yes')
                : t('No'),
              onClick: () => setIsOnlyConsecutiveReservationsPickerOpen(true),
            },
          ]}
        />
        <AppCardList
          title={t('Form settings')}
          items={[
            {
              title: t('Require email'),
              value: data?.formSettings?.email ? t('Yes') : t('No'),
              onClick: () => setIsEmailSettingsOpen(true),
            },
            {
              title: t('Require phone'),
              value: data?.formSettings?.phone ? t('Yes') : t('No'),
              onClick: () => setIsPhoneSettingsOpen(true),
            },
          ]}
        />
        <AppErrorButton
          text={t('Delete service')}
          onClick={() => setIsDeleteConfirmationOpen(true)}
        />
      </Box>
      <SummaryEditDrawer
        formParams={{
          open: isSummaryEditOpen,
          onClose: () => setIsSummaryEditOpen(false),
        }}
        experienceId={experienceId!}
      />
      <DurationPickerDrawer
        formParams={{
          open: isTimePickerOpen,
          onClose: () => setIsTimePickerOpen(false),
        }}
        value={data?.summary.duration ?? undefined}
        onSelect={handleDurationSelect}
      />
      <FutureBookingDaysLimitPickerDrawer
        formParams={{
          open: isFutureBookingDaysLimitPickerOpen,
          onClose: () => setIsFutureBookingDaysLimitPickerOpen(false),
        }}
        value={data?.limitSettings?.futureBookingDaysLimit ?? undefined}
        onSelect={handleFutureBookingDaysLimitSelect}
      />
      <MinimumNoticePeriodPickerDrawer
        formParams={{
          open: isMinimumNoticePeriodPickerOpen,
          onClose: () => setIsMinimumNoticePeriodPickerOpen(false),
        }}
        value={data?.limitSettings?.minimumNoticePeriod ?? undefined}
        onSelect={handleMinimumNoticePeriodSelect}
      />
      <DailyBookingLimitPickerDrawer
        formParams={{
          open: isDailyBookingLimitPickerOpen,
          onClose: () => setIsDailyBookingLimitPickerOpen(false),
        }}
        value={data?.limitSettings?.dailyBookingLimit ?? undefined}
        onSelect={handleDailyBookingLimitSelect}
      />
      <GapBetweenAppointmentsPickerDrawer
        formParams={{
          open: isGapBetweenAppointmentsPickerOpen,
          onClose: () => setIsGapBetweenAppointmentsPickerOpen(false),
        }}
        value={data?.limitSettings?.gapBetweenEvents ?? undefined}
        onSelect={handleGapBetweenAppointmentsSelect}
      />
      <OnlyConsecutiveReservationsPickerDrawer
        formParams={{
          open: isOnlyConsecutiveReservationsPickerOpen,
          onClose: () => setIsOnlyConsecutiveReservationsPickerOpen(false),
        }}
        onSelect={handleOnlyConsecutiveReservationsSelect}
      />
      <LinkInputDrawer
        formParams={{
          open: isLinkInputOpen,
          onClose: () => setIsLinkInputOpen(false),
        }}
        value={!data?.summary.link ? data?.id : data?.summary.link}
        title={t('Link for booking')}
        onSave={handleLinkSave}
        confirmText={t('Save')}
      />
      <QRCodeDisplayDrawer
        formParams={{
          open: isQRCodeDisplayOpen,
          onClose: () => setIsQRCodeDisplayOpen(false),
        }}
        experienceLink={getBookLink(data!)}
      />
      <DeleteConfirmationDrawer
        formParams={{
          open: isDeleteConfirmationOpen,
          onClose: () => setIsDeleteConfirmationOpen(false),
        }}
        onConfirm={handleDelete}
        text={
          <Trans
            i18nKey="Delete experience {experience} permanently?"
            values={{ experience: data?.summary.title }}
          />
        }
      />
      <ConnectGoogleCalendarDrawer
        formParams={{
          open: isConnectGoogleCalendarOpen,
          onClose: () => setIsConnectGoogleCalendarOpen(false),
        }}
        onConnectedGoogleCalendar={handleConnectedGoogleCalendar}
      />

      <GoogleCalendarPickerDrawer
        formParams={{
          open: isGoogleCalendarPickerOpen,
          onClose: () => setIsGoogleCalendarPickerOpen(false),
        }}
        experienceId={experienceId!}
        onSelect={() => setIsGoogleCalendarPickerOpen(false)}
      />

      <EnableGoogleMeetYesNoDrawer
        formParams={{
          open: isEnableGoogleMeetOpen,
          onClose: () => setIsEnableGoogleMeetOpen(false),
        }}
        experienceId={experienceId!}
      />

      <GoogleCalendarAvailabilitySelectDrawer
        formParams={{
          open: isGoogleCalendarAvailabilitySelectOpen,
          onClose: () => setIsGoogleCalendarAvailabilitySelectOpen(false),
        }}
        experienceId={experienceId!}
      />

      <DisableGoogleCalendarConfirmationDrawer
        formParams={{
          open: isDisableGoogleCalendarConfirmationOpen,
          onClose: () => setIsDisableGoogleCalendarConfirmationOpen(false),
        }}
        experienceId={experienceId!}
      />

      <ScheduleEditDrawer
        formParams={{
          open: isScheduleEditOpen,
          onClose: () => setIsScheduleEditOpen(false),
        }}
        experienceId={experienceId!}
      />

      <ParticipantsPickerDrawer
        formParams={{
          open: isParticipantsPickerOpen,
          onClose: () => setIsParticipantsPickerOpen(false),
        }}
        experienceId={experienceId!}
      />

      <FormFieldSettingsDrawer
        formParams={{
          open: isEmailSettingsOpen,
          onClose: () => setIsEmailSettingsOpen(false),
        }}
        experienceId={experienceId!}
        field="email"
      />
      <FormFieldSettingsDrawer
        formParams={{
          open: isPhoneSettingsOpen,
          onClose: () => setIsPhoneSettingsOpen(false),
        }}
        experienceId={experienceId!}
        field="phone"
      />
    </AppDrawer>
  );
};

export default ExperienceDetailsDrawer;
