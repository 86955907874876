import { Box } from '@mui/material';
import { Outlet, useOutletContext } from 'react-router-dom';
import { AppDrawerProvider } from '../../components/AppDrawerContext';

export type TopAppBarActionItem = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export type TopAppBarProps = {
  title: string;
  backButtonPath?: string;
  actionItems?: TopAppBarActionItem[];
  fab?: BottomAppBarFab;
  hide?: boolean;
};

export type BottomAppBarFab = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type BottomAppBarProps = {
  fab?: BottomAppBarFab;
};

export type AppBarsProps = {
  TopAppBar?: TopAppBarProps;
  BottomAppBar?: BottomAppBarProps;
};

export type AdminOutletContext = {
  setAppBars: (props: AppBarsProps) => void;
};

export function useAdminOutletContext() {
  return useOutletContext<AdminOutletContext>();
}

export default function BookLayout() {
  return (
    <AppDrawerProvider
      value={{
        paperProps: {
          sx: {
            maxWidth: '400px',
            margin: '0 auto',
          },
        },
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}>
        <Box
          sx={{
            // flexGrow: 1,
            overflowY: 'auto',
            ml: '16px',
            mr: '16px',
            // mb: 7,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            pt: 'max(env(safe-area-inset-top), 16px)',
            pb: 'max(env(safe-area-inset-bottom), 16px)',
          }}
        >
          <Box component="main" sx={{ maxWidth: '400px' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </AppDrawerProvider>
  );
}
