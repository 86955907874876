import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import BookingForm from '../../components/bookingForm/BookingForm';
import useExperience from '../../queryHelpers/useExperience';
import NotFoundPage from '../NotFoundPage';
import { CircularProgress } from '@mui/material';

interface BookingProcessPageProps {}

const BookingProcessPage: React.FC<BookingProcessPageProps> = (props) => {
  const { experienceId } = useParams<{ experienceId: string }>();
  const navigate = useNavigate();
  const experienceQuery = useExperience({ id: experienceId, link: experienceId, enabled: !!experienceId });

  const handleSave = (id: string) => {
    navigate(`/booking/${id}/success`);
  };

  if (experienceQuery.isLoading) {
    return <CircularProgress />;
  }

  if (!experienceQuery.data) {
    return <NotFoundPage />;
  }

  if (experienceQuery.data.deletedAt) {
    return <NotFoundPage />;
  }

  return (
    <>
      {experienceId && (
        <BookingForm experienceId={experienceId} onSave={handleSave} />
      )}
    </>
  );
};

export default BookingProcessPage;
