// site/theme.ts

import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/x-date-pickers/locales';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1: React.CSSProperties & {
      contrastText: string;
    };
    h2: React.CSSProperties & {
      contrastText: string;
    };
    h3: React.CSSProperties & {
      contrastText: string;
    };
    h4: React.CSSProperties & {
      contrastText: string;
    };
    h5: React.CSSProperties & {
      contrastText: string;
    };
    h6: React.CSSProperties & {
      contrastText: string;
    };
    panelHeader?: React.CSSProperties;
    experienceCardHeader?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    cardHeader?: React.CSSProperties;
    listItemLabel?: React.CSSProperties;
    listItemValue?: React.CSSProperties;
    drawerTitle?: React.CSSProperties;
    drawerSubtitle?: React.CSSProperties;
    closeText?: React.CSSProperties;
    paperBox?: React.CSSProperties;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariantsOptions {
    h1?: React.CSSProperties & {
      contrastText?: string;
    };
    h2?: React.CSSProperties & {
      contrastText?: string;
    };
    h3?: React.CSSProperties & {
      contrastText?: string;
    };
    h4?: React.CSSProperties & {
      contrastText?: string;
    };
    h5?: React.CSSProperties & {
      contrastText?: string;
    };
    h6?: React.CSSProperties & {
      contrastText?: string;
    };
    panelHeader?: React.CSSProperties;
    experienceCardHeader?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    cardHeader?: React.CSSProperties;
    listItemLabel?: React.CSSProperties;
    listItemValue?: React.CSSProperties;
    drawerTitle?: React.CSSProperties;
    drawerSubtitle?: React.CSSProperties;
    closeText?: React.CSSProperties;
    paperBox?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    panelHeader: true;
    experienceCardHeader: true;
    inputLabel: true;
    cardHeader: true;
    listItemLabel: true;
    listItemValue: true;
    drawerTitle: true;
    drawerSubtitle: true;
    closeText: true;
    paperBox: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

declare module '@mui/material/styles' {
  interface TypeBackground {
    boxInPaper?: string;
  }
  interface Palette {
    picker: {
      text: string;
      textSelected: string;
      textUnselected: string;
    };
  }
  interface PaletteOptions {
    picker?: {
      text?: string;
      textSelected?: string;
      textUnselected?: string;
    };
  }
}

const darkTheme = createTheme(
  {
    typography: {
      fontFamily: 'Roboto', //"'SF Pro Text', sans-serif",
      h1: {
        fontSize: '32px',
        fontWeight: 500,
        color: '#FFFFFF',
      },
      h2: {
        fontSize: '28px',
        fontWeight: 500,
        color: '#FFFFFF',
      },
      h3: {
        fontSize: '24px',
        fontWeight: 500,
        color: '#FFFFFF',
      },
      h4: {
        fontSize: '20px',
        fontWeight: 500,
        color: '#FFFFFF',
      },
      h5: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#FFFFFF',
      },
      h6: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#FFFFFF',
      },
      body1: {
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '16.41px',
      },
      panelHeader: {
        fontWeight: 400,
        color: '#636366',
      },
      cardHeader: {
        fontWeight: 400,
        color: '#FFFFFF',
        fontSize: 16,
      },
      experienceCardHeader: {
        fontWeight: 400,
        color: '#FFFFFF',
        fontSize: '12px',
        lineHeight: '14.16px',
      },
      inputLabel: {
        fontWeight: 400,
        color: '#FFFFFF',
        fontSize: '12px',
        lineHeight: '14.06px',
      },
      drawerTitle: {
        fontWeight: 400,
        color: '#FFFFFF',
        fontSize: '16px',
        lineHeight: '18.75px',
      },
      drawerSubtitle: {
        fontWeight: 300,
        color: '#FFFFFF',
        fontSize: '14px',
        lineHeight: '16.41px',
      },
      listItemLabel: {
        fontWeight: 300,
        color: '#FFFFFF',
        fontSize: '14px',
        lineHeight: '16.41px',
      },
      listItemValue: {
        fontWeight: 600,
        color: '#FFFFFF',
        fontSize: '14px',
        lineHeight: '16.41px',
      },
      closeText: {
        fontWeight: 400,
        color: '#51B9FF',
        fontSize: '15px',
        lineHeight: '17.58px',
      },
      paperBox: {
        fontWeight: 400,
        color: '#FFFFFF',
        fontSize: '10px',
        lineHeight: '11.72px',
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#578CC7',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#4DB6AC',
        contrastText: '#FFF',
      },
      background: {
        default: '#1B1B1B',
        paper: '#2A2A2A',
        boxInPaper: '#3A3A3A',
      },
      action: {
        active: '#578CC7',
      },
      picker: {
        text: '#FFFFFF',
        textSelected: '#FFFFFF',
        textUnselected: '#666666',
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            color: '#FFFFFF',
            fontSize: '12px',
            lineHeight: '14.06px',
            marginTop: 0,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            background: '#393939',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #393939',
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #393939',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: '1px solid #393939',
              },
            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
              {
                border: '1px solid #393939',
              },
            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
              {
                border: '1px solid #393939',
              },
            // '&.Mui-focused': {
            //   '& .MuiOutlinedInput-notchedOutline': {
            //     border: '1px solid #393939',
            //   },
            // },
          },
        },
        variants: [
          {
            props: { size: 'small' },
            style: {
              '& .MuiOutlinedInput-input': {
                fontSize: '12px',
                lineHeight: '14.06px',
                fontWeight: 300,
              },
            },
          },
        ],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: '#fff',
            border: '1px solid #393939',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '28px',
          },
        },
        variants: [
          {
            props: { variant: 'link' },
            style: {
              fontWeight: 300,
              fontSize: '14px',
              lineHeight: '28px',
              color: '#578CC7',
              textTransform: 'none',
              minWidth: 'auto',
              minHeight: 'auto',
              '&:hover': {
                backgroundColor: '#4A7AB3',
                filter: 'brightness(0.75)',
              },
              '&:active': {
                backgroundColor: '#4A7AB3',
                filter: 'brightness(0.75)',
              },
            },
          },
        ],
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            // backgroundColor: '#1B1B1B',
            color: '#fff',
            background: '#1B1B1B',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '32px',
            paddingBottom: '40px',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            background: 'transparent',
            padding: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: 'rgba(87, 140, 199, 0.25)',
              boxShadow: '0 0 4px 2px rgba(87, 140, 199, 0.25)',
              color: '#FFFFFF',
              borderRadius: '8px',
              position: 'relative',
              zIndex: 2,
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'rgba(87, 140, 199, 0.3)',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            color: '#A4A8AC',
            // border: 1,
          },
        },
      },
      MuiBottomNavigation: {
        defaultProps: {
          style: {
            backgroundColor: '#1B1B1B',
            color: '#A4A8AC',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#393939',
            color: '#FFFFFF',
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: '16.41px',
            paddingBottom: '0!important',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '12px',
            paddingBottom: '12px!important',
          },
        },
      },
      MuiChip: {
        variants: [
          {
            props: { color: 'error' },
            style: {
              textTransform: 'uppercase',
              borderRadius: '4px',
              backgroundColor: '#F1D4D7',
              color: '#FE3B30',
              height: '16px',
              '& .MuiChip-label': {
                color: '#FE3B30',
                padding: '4px',
                fontWeight: 300,
                fontSize: '8px',
              },
            },
          },
        ],
      },
    },
  },
  ruRU,
);

export default darkTheme;
