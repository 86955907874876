import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppDrawer from '../AppDrawer';
import { FormParams } from '../../types';
import useExperience from '../../queryHelpers/useExperience';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';
import { Box, Autocomplete, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';

interface ParticipantsPickerDrawerProps {
  formParams: FormParams;
  experienceId: string;
}

interface ParticipantsFormInputs {
  invitees: string[];
}

const ParticipantsPickerDrawer: React.FC<ParticipantsPickerDrawerProps> = ({
  formParams,
  experienceId,
}) => {
  const { t } = useTranslation();
  const { data: experience } = useExperience({
    id: experienceId,
    enabled: !!experienceId,
  });
  const updateExperience = useUpdateExperience(experienceId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<ParticipantsFormInputs>({
    defaultValues: {
      invitees: experience?.googleCalendarSettings?.invitees || [],
    },
  });

  const onSubmit = async (data: ParticipantsFormInputs) => {
    try {
      setIsSubmitting(true);
      setError(null);
      await updateExperience.mutateAsync({
        id: experienceId,
        googleCalendarSettings: {
          ...experience?.googleCalendarSettings,
          invitees: data.invitees,
          calendarIdForNewEvents: experience?.googleCalendarSettings?.calendarIdForNewEvents || null,
          createGoogleMeetLink: experience?.googleCalendarSettings?.createGoogleMeetLink || false,
          calendarIdsForAvailability: experience?.googleCalendarSettings?.calendarIdsForAvailability || null,
        },
      });
      formParams.onClose();
    } catch (error) {
      setError(t('Failed to update participants'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppDrawer
      formParams={formParams}
      title={t('Meeting participants')}
      description={t('Add email addresses of participants who should be invited to the meeting')}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <Box sx={{ my: '32px' }}>
          <Controller
            name="invitees"
            control={form.control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                freeSolo
                options={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    placeholder={t('Enter email and press Enter')}
                    error={!!form.formState.errors.invitees}
                  />
                )}
                onChange={(_, newValue) => field.onChange(newValue)}
                value={field.value || []}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '8px',
                  },
                  '& .MuiAutocomplete-tag': {
                    margin: '2px',
                  },
                }}
              />
            )}
          />

          {error && (
            <Typography
              sx={{
                color: 'error.main',
                mt: 2,
                textAlign: 'center',
              }}
            >
              {error}
            </Typography>
          )}
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          onClick={form.handleSubmit(onSubmit)}
          loading={isSubmitting}
          fullWidth
        >
          {t('Save')}
        </LoadingButton>
      </Box>
    </AppDrawer>
  );
};

export default ParticipantsPickerDrawer;
